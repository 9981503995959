import React, { useState, useEffect, Suspense } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { signIn, updatePassword, updateUserAttribute } from 'aws-amplify/auth';
import { Link } from 'react-router-dom';
import { LoaderButton } from './LoaderButton';
import { Loading } from './Loading';
import { useRouter, useFormFields } from '../assets/scripts/hooksLib';
import { adjustHeader } from '../assets/scripts/utilsLib';
import '../assets/styles/login.scss';

const ErrorModal = React.lazy(() => import('../app/components/ErrorMessage'));

/**
 * User login form
 * @returns {HTMLElement} html for login block
 */
export default function Login() {
    const router = useRouter();
    // const { userHasAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const [fields, handleFieldChange] = useFormFields({
        email: '',
        name: '',
        password: ''
    });
    const [signUp, setSignUp] = useState('/signup.html');
    const [newPassword, setNewPassword] = useFormFields({
        password: '',
        confirm: ''
    });
    const [changePasswordForm, setChangePasswordForm] = useState(false);
    const [authenticatedUser, setAuthenticatedUser] = useState();
    const [errorMessage, setErrorMessage] = useState(null);
    const [showErrorModal, setShowErrorModal] = useState(false);

    /**
     * @typedef {{
     * onkeyup: number
     * }} KeyEvent
     */

    /**
     * Sets the Error Modal Window with error message and context
     * @param {string} title error message window title
     * @param {string} message error messsage
     * @param {[string]} level (optional) level of severity, e.g. danger, warning, info, etc.
     * @param {[string]} buttonTxt (optional) text label on button
     * @returns {void}
     */
    const setErrorOccurred = (title, message, level = 'danger', buttonTxt = 'OK') => {
        setErrorMessage({
            title: title,
            message: message,
            level: level,
            buttonText: buttonTxt
        });
        setShowErrorModal(true);
    };

    /**
     * Switches the Error modal window visibility to true or false
     * @returns {void}
     */
    const showErrorMessageModal = () => {
        if (showErrorModal) {
            setShowErrorModal(false);
            setErrorMessage({});
        } else {
            setShowErrorModal(true);
        }
    };

    // adjusts the page header
    useEffect(() => {
        let isMounted = true;

        if (isMounted) {
            adjustHeader();
        }

        if (router.query && router.query.redirect) {
            setSignUp(`/signup.html?redirect=${router.query.redirect}`);
        }

        // clean up async calls
        return () => { isMounted = false; };
    }, [router, setSignUp]);

    /**
     * Checks and confirms the form fields are complete
     * @returns {boolean} true is complete, or false is incomplete
     */
    const validateForm = () => {
        return fields.email.length > 0 && fields.password.length > 0;
    };

    /**
     * Authenticates the user credentials and redirects if valid
     * @param {MouseEvent|KeyEvent} event mouse click or Enter key pressed
     * @returns {void}
     */
    const handleSubmit = async (event) => {
        event.preventDefault();

        setIsLoading(true);
        // console.log(fields);

        try {
            await signIn({
                username: fields.email,
                password: fields.password
            })
                .then(async user => {
                    await updateUserAttribute({
                        userAttribute: {
                            attributeKey: 'custom:last_login_at',
                            value: `${Date.now()}`
                        }
                    });

                    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                        setAuthenticatedUser(user);
                        setIsLoading(false);
                        setChangePasswordForm(true);
                    } else {
                        // userHasAuthenticated(true);
                        setIsLoading(false);

                        if (router.query.redirect) {
                            // router.history(router.query.redirect);
                            location.href = router.query.redirect;
                        } else {
                            // router.history('/apps');
                            location.href = '/app';
                        }
                    }
                })
                .catch(err => {
                    // console.log(err);
                    if (err.name === 'UserNotConfirmedException'
                        || err.code === 'UserNotConfirmedException') {
                        // window.alert('Please confirm your user account by checking your email for the Stitchz.net verification code from no-reply@stitchz.net.');
                        setErrorOccurred('An Error Occurred', 'Please confirm your user account by checking your email for the Stitchz.net verification code from no-reply@stitchz.net.');
                        location.href = '/signup.html?confirm=true';
                    } else if (err.name === 'UserAlreadyAuthenticatedException') {
                        location.href = '/app';
                    } else if (err.name === 'NotAuthorizedException') {
                        setErrorOccurred('Authentication Error', err.message);
                        setIsLoading(false);
                    } else {
                        // onError(err);
                        setErrorOccurred('An Unexpected Error Occurred', err);
                        setIsLoading(false);
                    }
                });
        } catch (e) {
            // onError(e);
            setErrorOccurred('An Error Occurred', e);
            setIsLoading(false);
        }
    };

    /**
     * Checks and confirms the passwords match
     * @returns {boolean} true is complete, or false is incomplete
     */
    const validatePasswordsMatch = () => {
        return fields.name.length > 0
            && newPassword.password.length > 0
            && newPassword.confirm.length > 0
            && newPassword.password === newPassword.confirm;
    };

    /**
     * First time signing in, so let's update the user's password, add a username and redirect if valid
     * @param {MouseEvent|KeyEvent} event mouse click or Enter key pressed
     * @returns {void}
     */
    const handleChangePasswordSubmit = async (event) => {
        event.preventDefault();

        setIsLoading(true);

        try {
            await updatePassword(
                authenticatedUser,         // the Cognito User Object
                newPassword.password,      // the new password
                {
                    name: fields.name || fields.email
                }
            ).then(() => {
                setIsLoading(false);
                if (router.query.redirect && !['%2Flogin', '%2Fsignup'].includes(router.query.redirect)) {
                    // router.history(router.query.redirect);
                    location.href = router.query.redirect;
                } else {
                    // router.history('/apps');
                    location.href = '/apps';
                }
            })
                .catch(err => {
                    console.log(err);
                    // router.history('/signup');
                    location.href = '/signup.html';
                });
        } catch (e) {
            // onError(e);
            setErrorOccurred('An Unknown Error Occurred', e);
            setIsLoading(false);
        }
    };

    /**
     * Builds the change password form with optional username field
     * @returns {HTMLElement} html
     */
    const renderChangePasswordForm = () => {
        return (
            <Row className='Login'>
                <Col sm={12} md={{ span: 6, offset: 3 }}>
                    <h4 className='center'>Change Your Temporary Password</h4>
                    <form onSubmit={handleChangePasswordSubmit}>
                        <Form.Group controlId='name'>
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                autoFocus
                                placeholder='Add Your Username'
                                type='text'
                                value={fields.name}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                        <Form.Group controlId='password'>
                            <Form.Label>New Password</Form.Label>
                            <Form.Control
                                autoFocus
                                type='password'
                                value={newPassword.password}
                                onChange={setNewPassword}
                            />
                        </Form.Group>
                        <Form.Group controlId='confirm'>
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control
                                type='password'
                                onChange={setNewPassword}
                                value={newPassword.confirm}
                            />
                        </Form.Group>
                        <LoaderButton
                            type='submit'
                            isLoading={isLoading}
                            disabled={!validatePasswordsMatch()}
                        >
                            Save
                        </LoaderButton>
                    </form>
                </Col>
            </Row>
        );
    };

    return (
        <>
            {changePasswordForm ?
                renderChangePasswordForm() :
                <Row className='Login'>
                    <Col sm={12} md={{ span: 6, offset: 3 }}>
                        <form onSubmit={handleSubmit}>
                            <Form.Group controlId='email'>
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    autoFocus
                                    type='email'
                                    value={fields.email}
                                    onChange={handleFieldChange}
                                />
                            </Form.Group>
                            <Form.Group controlId='password'>
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    value={fields.password}
                                    onChange={handleFieldChange}
                                    type='password'
                                />
                            </Form.Group>
                            <Link to='/login/reset.html' rel='nofollow'>Forgot password?</Link>
                            <Link to={signUp}>Sign Up</Link>
                            <LoaderButton
                                block='true'
                                type='submit'
                                isLoading={isLoading}
                                disabled={!validateForm()}
                            >
                                Login
                            </LoaderButton>
                        </form>
                    </Col>
                </Row>
            }
            {errorMessage ?
                <Suspense fallback={<Loading pageLoading={true} />}>
                    <ErrorModal message={errorMessage} show={showErrorModal} handleClose={showErrorMessageModal} />
                </Suspense>
                : ''
            }
        </>
    );
}
